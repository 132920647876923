<template>
	<b-overlay :show="loading" variant="light" spinner-type="grow">
		<b-form @submit.prevent="saveRecord" class="text-left m-2">

			<b-alert :show="!!error" variant="danger" v-html="error.message"></b-alert>

			<b-card no-body class="mb-2">
				<b-tabs card>
					<b-tab title="Profile" active>

						<b-form-group
								label="Email address:"
								label-for="profileEmail"
								description="This is also your account username"
								class="mb-4 p-4 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.email"
								:state="!bvDataForm.validationErrors.email"
						>
							<b-form-input
									id="profileEmail"
									v-model="record.email"
									type="email"
									required
									placeholder="Your email address"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Password:"
								label-for="profilePassword"
								class="mb-4 p-4 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.password"
								:state="!bvDataForm.validationErrors.password"
						>

							<template v-if="!isNew">
								<b-link v-if="!showChangePassword" @click="showChangePassword = true"
								        class="d-block mt-4">
									Change
								</b-link>
								<b-link v-if="showChangePassword" @click="showChangePassword = false"
								        class="d-block mt-4">
									Cancel
								</b-link>
							</template>

							<b-form-input
									v-if="isNew || showChangePassword"
									id="profilePassword"
									v-model="password"
									type="password"
									required
									autocomplete="off"
									data-lpignore="true"
									placeholder="Enter your new password"
									class="d-inline-block w-50"
							></b-form-input>
							<b-form-input
									v-if="isNew || showChangePassword"
									id="profilePasswordConfirm"
									v-model="passwordConfirm"
									type="password"
									autocomplete="off"
									data-lpignore="true"
									required
									placeholder="Confirm your new password"
									class="d-inline-block w-50"
							></b-form-input>

						</b-form-group>

						<b-form-group
								label="First name:"
								label-for="profileFirstName"
								class="mb-4 p-4 shadow-sm"
								:invalid-feedback="bvDataForm.validationErrors.firstName"
								:state="!bvDataForm.validationErrors.firstName"
						>
							<b-form-input
									id="profileFirstName"
									v-model="record.firstName"
									required
									placeholder="Enter your first name"
							></b-form-input>
						</b-form-group>

						<b-form-group
								label="Last name:"
								label-for="profileLastName"
								:invalid-feedback="bvDataForm.validationErrors.lastName"
								:state="!bvDataForm.validationErrors.lastName"
								class="mb-4 p-4 shadow-sm"
						>
							<b-form-input
									id="profileLastName"
									v-model="record.lastName"
									required
									placeholder="Enter your last name"
							></b-form-input>
						</b-form-group>

						<b-form-group
								v-if="isAdmin"
								:invalid-feedback="bvDataForm.validationErrors.admin"
								:state="!bvDataForm.validationErrors.admin"
								class="mb-4 p-4 shadow-sm"
						>
							<b-form-checkbox id="profileAdmin" v-model="record.admin"
							                 name="check-button" switch
							                 :disabled="user.id === record.id"
							>
								Administrator?
							</b-form-checkbox>
						</b-form-group>

						<b-form-group
								:invalid-feedback="bvDataForm.validationErrors.enabled"
								:state="!bvDataForm.validationErrors.enabled"
								class="mb-4 p-4 shadow-sm"
						>
							<b-form-checkbox id="profileEnabled" v-model="record.enabled"
							                 name="check-button" switch
							                 :disabled="user.id === record.id"
							>
								Enabled?
							</b-form-checkbox>
						</b-form-group>

					</b-tab>
					<b-tab title="Settings">
						<b-card-text>No settings defined yet!</b-card-text>
					</b-tab>
				</b-tabs>
			</b-card>

			<div class="d-flex justify-content-end">
				<b-button v-if="!isNew" type="button" class="text-danger" variant="link"
				          @click="deleteRecord">
					Delete
				</b-button>
				<b-button type="button" :disabled="!isNew && !bvDataForm.dirty" class="ml-2 mr-0" variant="secondary"
				          @click="bvDataFormReset">
					{{ isNew ? 'Cancel' : 'Reset' }}
				</b-button>
				<b-button type="submit" :disabled="!bvDataForm.dirty" class="ml-2 mr-0" variant="primary">
					{{ isNew ? 'Create' : 'Save Changes' }}
				</b-button>
			</div>

		</b-form>
	</b-overlay>
</template>

<script>

import { cloneDeep, omit } from 'lodash-es';
import bvDataFormMixin     from '@/mixins/bvDataForm.mixin';

export default {

    name      : 'DataFormUser',
    mixins    : [ bvDataFormMixin ],
    components: {},

    props: {

        record: {
            type: Object
        }

    },

    computed: {
        isNew () {
            return this.record && !this.record.id;
        }
    },

    data () {
        return {
            bvDataForm        : this.bvDataFormGetDataDefaults (
                {
                    id          : 'usersForm',
                    resourceName: 'user'
                }
            ),
            loading           : true,
            error             : false,
            showChangePassword: false,
            password          : null,
            passwordConfirm   : null
        };
    },

    watch: {
        password       : {
            handler: function () {
                this.bvDataForm.dirty = true;
            }
        },
        passwordConfirm: {
            handler: function () {
                this.bvDataForm.dirty = true;
            }
        }
    },

    methods: {

        async saveRecord () {
            try {

                this.loading = true;
                this.error = false;

                const saveData = cloneDeep ( omit ( this.record, this.$utils.auditFields ) );
                let requiresLogin = this.record.id === this.user.id && this.record.email !== this.user.email;

                // Password change requested?
                if ( this.isNew || this.showChangePassword ) {

                    if ( this.password !== this.passwordConfirm ) {
                        const e = new Error ();
                        e.errors = [
                            {
                                message: 'Your new and confirm passwords do not match.',
                                fields : [ 'password' ]
                            }
                        ];
                        throw e;
                    }

                    requiresLogin = this.record.id === this.user.id;
                    saveData.password = this.password;

                }

                // Save changes
                await this.bvDataFormSave ( saveData );
                this.$utils.logger ( 'info', '[AUTH] Changes saved to user profile.' );
                this.$bvToast.toast (
                    `Changes for ${ saveData.email } were saved successfully.`,
                    {
                        title        : 'Saved',
                        autoHideDelay: 3000,
                        toaster      : 'b-toaster-top-center',
                        solid        : true
                    }
                );

                // Login required?
                if ( requiresLogin ) {
                    await this.$alert ( {
                        title: 'Login credentials changed',
                        html : 'Your login credentials have changed, you will need to login again.'
                    } );
                    await this.$store.dispatch ( 'user/logout' );
                    await this.$router.push ( { name: 'login' } );
                    return false;
                }

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        async deleteRecord () {
            try {

                this.loading = true;
                this.error = false;

                // Delete record
                const { data } = await this.bvDataFormDelete ();
                this.$utils.logger ( 'info', '[AUTH] User deleted.', data );
                this.$bvToast.toast (
                    `User ${ data.email } deleted successfully.`,
                    {
                        title        : 'Deleted',
                        autoHideDelay: 3000,
                        toaster      : 'b-toaster-top-center',
                        solid        : true
                    }
                );

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        }

    },

    mounted () {

        // Ready
        this.loading = false;

    }

};

</script>

<style scoped lang="scss">

</style>